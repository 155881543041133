import * as yup from 'yup'

interface DocumentValidationOptions {
  maxFiles?: number
  minFiles?: number
  required?: boolean
  allowedMimeTypes?: string[]
}
// TODO check function

export default function useValidations () {
  const { $t } = useI18n()

  const emailValidation = yup.string().matches(/[\w.-]+@[\w.-]+\.\w{2,}/, String($t('components.validations.invalidEmail'))).required(String($t('components.validations.emailRequired')))

  const passwordValidation = yup.string().required(String($t('components.validations.passwordRequired'))).min(8, String($t('components.validations.passwordMinLength')))

  const phoneValidation = yup.string().matches(/^(\+420|\+421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/, { message: String($t('components.validations.invalidPhone')), excludeEmptyString: true })

  // IČO
  const companyIdNumberValidation = yup.string().matches(/^\d{8}$/, String($t('components.validations.invalidCompanyId'))).required(String($t('components.validations.companyIdRequired')))
  // DIČ
  const companyTaxIdNumberValidation = yup.string()
    .nullable()
    .matches(/^(|[a-zA-Z]{2,4}[0-9]{8,12})$/, String($t('components.validations.invalidTaxId')))

  // IBAN
  const ibanValidation = yup.string().uppercase().transform(value => value.replace(/\s+/g, ''))
    .matches(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/, String($t('components.validations.invalidIban')))
    .test('iban-check', String($t('components.validations.invalidIban')), value => {
      if (!value) return false
      try {
        // IBAN checksum algorithm: https://stackoverflow.com/questions/72689001/iban-validation-checksum
        const rearranged = value.slice(4) + value.slice(0, 4)
        const numericIban = rearranged.replace(/[A-Z]/g, match => String(match.charCodeAt(0) - 55))
        return BigInt(numericIban) % BigInt(97) === BigInt(1)
      }
      catch (_e) {
        return false
      }
    })

  // SWIFT/BIC
  const swiftBicValidation = yup.string().uppercase().transform(value => value.replace(/\s+/g, ''))
    .matches(/^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/, String($t('components.validations.invalidSwiftBic')))

  function fileValidation (options?: DocumentValidationOptions) {
    const {
      maxFiles,
      minFiles,
      required = false,
      allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf'],
    } = options || {}

    let schema = yup.array()

    if (required)
      schema = schema.test('required', String($t('components.validations.fileRequired')), files => !!files?.length)

    return schema
      .test('maxFiles', String($t('components.validations.maxFiles', { n: String(maxFiles) })), files => {
        if (maxFiles && files && files.length > maxFiles)
          return false

        return true
      })
      .test('minFiles', String($t('components.validations.minFiles', { n: String(minFiles) })), files => {
        if (minFiles && files && files.length < minFiles)
          return false

        return true
      })

      .of(
        yup
          .mixed<File>()
          .test('fileType', String($t('components.validations.unsupportedFileType')), file => {
            if (!file || !file.type)
              return false

            return allowedMimeTypes.includes(file.type)
          })
          .test('fileSize', String($t('components.validations.maxFileSizeExceeded')), file => {
            if (!file)
              return true

            const maxSizeInBytes = 4 * 1024 * 1024 // 4 MB
            return file.size <= maxSizeInBytes
          }),
      )
  }

  function confirmPasswordValidation (passwordFieldName: string) {
    return yup.string().required(String($t('components.validations.confirmPasswordRequired'))).oneOf([yup.ref(passwordFieldName)], String($t('components.validations.passwordsMustMatch')))
  }

  function passwordRequirements (input: string) {
    return [
      {
        text: String($t('components.validations.minCharacters')),
        valid: yup.string().min(8).required().isValidSync(input),
      },
      {
        text: String($t('components.validations.upperLowerCase')),
        valid: yup.string().matches(/[a-z]/).matches(/[A-Z]/).required().isValidSync(input),
      },
      {
        text: String($t('components.validations.oneNumber')),
        valid: yup.string().matches(/[0-9]/).required().isValidSync(input),
      },
      {
        text: String($t('components.validations.oneSpecialCharacter')),
        valid: yup.string().matches(/[!-/:-@[-`{-~]/).required().isValidSync(input),
      },
    ]
  }

  function cryptoAddressValidation (address: string, chain: string) {
    const cryptoAddresses = [
      { chain: 'Bitcoin', regex: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,62}$/ },
      { chain: 'Ethereum', regex: /^0x[a-fA-F0-9]{40}$/ },
      { chain: 'Tron', regex: /^T[1-9A-HJ-NP-Za-km-z]{33}$/ },
      { chain: 'Monero', regex: /^[48][0-9A-Za-z]{94}$/ },
      { chain: 'Cardano', regex: /^addr1[0-9a-z]{98}$/ },
      { chain: 'Solana', regex: /^[1-9A-HJ-NP-Za-km-z]{44}$/ },
      { chain: 'Polkadot', regex: /^[1-9A-HJ-NP-Za-km-z]{47,48}$/ },
      { chain: 'Binance', regex: /^0x[a-fA-F0-9]{40}$/ },
      { chain: 'Arbitrum', regex: /^0x[a-fA-F0-9]{40}$/ },
      { chain: 'Matic', regex: /^0x[a-fA-F0-9]{40}$/ },
      { chain: 'Ripple', regex: /^r[1-9A-HJ-NP-Za-km-z]{25,34}$/ },
      { chain: 'Litecoin', regex: /^(L|M)[a-km-zA-HJ-NP-Z1-9]{26,34}$/ },
      { chain: 'Stellar Lumens', regex: /^G[A-DH-NP-Z1-9]{55}$/ },
      { chain: 'Dogecoin', regex: /^D[1-9A-Za-z]{25,34}$/ },
      { chain: 'Polygon', regex: /^0x[a-fA-F0-9]{40}$/ },
    ]
    const crypto = cryptoAddresses.find(crypto => crypto.chain === chain)
    if (!crypto)
      return false

    const regex = crypto.regex

    return regex.test(address)
  }
  return {
    emailValidation,
    passwordValidation,
    phoneValidation,
    companyIdNumberValidation,
    companyTaxIdNumberValidation,
    ibanValidation,
    swiftBicValidation,
    fileValidation,
    confirmPasswordValidation,
    passwordRequirements,
    cryptoAddressValidation,
  }
}
